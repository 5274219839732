import React, { useContext } from "react"
import Layout from "../../components/layout"
import styles from "./index.module.scss"
import ThemeContext from "../../context/ThemeContext"
import t from '../../services/GetTranslationValue'
import r from "../../services/Router"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import ContentWrapper from '../../components/text-helpers/content-wrapper'
import SEO from "../../components/seo"
import localize from "../../components/localize"
import MainSearch from "../../components/main-search/main-search"

const Content = ({ platforms, translations, locale, bonuses, filters }) => {
  
  return (
    <MainSearch manualFilters={true} headerFilters={filters} locale={locale} bonuses={bonuses} />
  )
}

const CasinoPlatforms = ({ data, pageContext, location }) => {

  let pageData = data.sanityGenericLandingPage
  if (!pageData) pageData = {}

  const { locale, alternateLocaleSlugs } = pageContext
  let { translations, bonuses } = useContext(ThemeContext)

  let dataPlatforms = data.allSanityPlatform.edges;
  let dataCasinos = data.allSanityCasino.edges;

  let platformFilters = [];
  
  dataPlatforms.map(x => {
    x.baseName = x.node.name
    x.casinosCount = dataCasinos.filter(y => y.node.platform.name === x.node.name).length 
    platformFilters.push(x);
  })
  
  platformFilters.sort((a, b) => a.casinosCount - b.casinosCount).reverse()

  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <ContentWrapper title={pageData.h1Tag} header={pageData._rawHeaderText}
        footer={pageData._rawFooterText}
        headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}
      >
        <Content
          translations={translations}
          locale={locale}
          platforms={dataPlatforms}
          bonuses={bonuses}
          filters={platformFilters}
        />
      </ContentWrapper>
    </Layout>
  )
}

export default localize(CasinoPlatforms)
export const query = graphql`
  query PlatformLandingQuery {
    sanitySilo (_id: {eq: "2a6640d6-d7c0-4fa1-915b-8dbdfc7b0570"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGenericLandingPage(
      name: { eq: "Platforms" }
    ) {
      ...GenericLanding
    }
    allSanityPlatform {
      edges {
        node {
          name
        }
      }
    }
    allSanityCasino {
      edges {
        node {
          name
          platform {
            name
          }
        }
      }
    }
  }
`
